import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import logo from "./logo_hokben_1.png";
import "./App.css";

const AvayaComponent = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isExecuted, setIsExecuted] = useState(false);

  const handleClick = (key) => {
    console.info("sending dtmf key: " + key);
    $.publish && $.publish("voice.interaction.dtmf", [{ tone: key }]);
  };

  useEffect(() => {
    if (!isExecuted) {
      const button = document.getElementById("helper-call");
      if (button) {
        button.click();
      }

      setIsExecuted(true);
    }
  }, [isExecuted]);
  const handleVoiceCall = () => {
    console.info("Reference Client: attempting to make Web Voice call");

    const x = window.innerWidth / 2;
    const y = window.innerHeight / 4;

    voiceUi.openWindow(x, y);
    VOICE.start(config, workRequest, optional);
  };

  const closeWindow = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const isMobile = urlParams.get("isMobile");

    if (isMobile === '1') {
      window.opener = window;
      var win = window.open("", "_self");
      win.close();
      top.close();
    }
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="card-phone">
      {/* <!-- Notification bar --> */}
      <div
        id="client-toast"
        className="mdl-js-snackbar mdl-snackbar"
        style={{ display: "none" }}
      >
        <div className="mdl-snackbar__text"></div>
        <button className="mdl-snackbar__action" type="button"></button>
      </div>

      <div className="text-center hokben-container">
        <h2 className="text-white" style={{ marginTop: 0 }}>
          Hokben Delivery
        </h2>
        <p className="text-white">1-500-505</p>
        <img
          className="hokben-img mt-5"
          src={logo}
          width="150"
          height="150"
          alt="Logo Hokben"
        />
        <h5 className="text-white mt-3" id="webrtc_duration">
          {" "}
          &nbsp;
        </h5>
        <p
          className="text-white"
          style={{ fontSize: "small", paddingInline: "15%" }}
        >
          Anda sedang terhubung dengan layanan Hokben Delivery
        </p>
        <div
          className="mt-5 mb-3"
          style={{ display: "flex", gap: "15px", marginTop: "1.5rem" }}
        >
          <button
            className="btn-main buttonSpeakerCall p-3"
            style={{ display: "none" }}
            id="helper-call"
            onClick={handleVoiceCall}
          >
            Call
          </button>
          <button className="btn-main buttonSpeakerCall p-3" id="phoneCall">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="#FFFFFF"
              className="bi bi-volume-up-fill"
              viewBox="0 0 16 16"
            >
              <path d="M11.536 14.01A8.47 8.47 0 0 0 14.026 8a8.47 8.47 0 0 0-2.49-6.01l-.708.707A7.48 7.48 0 0 1 13.025 8c0 2.071-.84 3.946-2.197 5.303z" />
              <path d="M10.121 12.596A6.48 6.48 0 0 0 12.025 8a6.48 6.48 0 0 0-1.904-4.596l-.707.707A5.48 5.48 0 0 1 11.025 8a5.48 5.48 0 0 1-1.61 3.89z" />
              <path d="M8.707 11.182A4.5 4.5 0 0 0 10.025 8a4.5 4.5 0 0 0-1.318-3.182L8 5.525A3.5 3.5 0 0 1 9.025 8 3.5 3.5 0 0 1 8 10.475zM6.717 3.55A.5.5 0 0 1 7 4v8a.5.5 0 0 1-.812.39L3.825 10.5H1.5A.5.5 0 0 1 1 10V6a.5.5 0 0 1 .5-.5h2.325l2.363-1.89a.5.5 0 0 1 .529-.06" />
            </svg>
          </button>
          <button
            className="btn-main btn-danger rounded-circle p-3"
            id="webrtc_end"
            onClick={closeWindow}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="#fff"
              className="bi bi-telephone-x-fill"
              viewBox="0 0 16 16"
            >
              <path
                fillRule="evenodd"
                d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.68.68 0 0 0 .178.643l2.457 2.457a.68.68 0 0 0 .644.178l2.189-.547a1.75 1.75 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.6 18.6 0 0 1-7.01-4.42 18.6 18.6 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877zm9.261 1.135a.5.5 0 0 1 .708 0L13 2.793l1.146-1.147a.5.5 0 0 1 .708.708L13.707 3.5l1.147 1.146a.5.5 0 0 1-.708.708L13 4.207l-1.146 1.147a.5.5 0 0 1-.708-.708L12.293 3.5l-1.147-1.146a.5.5 0 0 1 0-.708"
              />
            </svg>
          </button>
          <button className="btn-main buttonPhonePad p-3" onClick={openModal}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="24"
              fill="#fff"
              className="bi bi-grid-3x4-gap-fill"
              viewBox="0 0 16 20"
            >
              <path d="M1 2a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1zm5 0a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1zm5 0a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1zM1 7a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1zm5 0a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1zm5 0a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1zM1 12a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1zm5 0a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1zm5 0a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1z M6 17a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1z" />
            </svg>
          </button>
        </div>

        {/* Modal untuk PhonePad */}
        <Modal
          isOpen={isModalOpen}
          onRequestClose={closeModal}
          contentLabel="Phone Pad"
          className="modal-content"
          overlayClassName="modal-overlay"
        >
          <ul className="phonepad-grid dtmf-interface">
            <li>
              <button
                name="dtmfKey"
                onClick={() => handleClick("1")}
                key="1"
                id="1"
                className="btn btn-outline-dark phonepad-btn"
              >
                1
              </button>
            </li>
            <li>
              <button
                name="dtmfKey"
                onClick={() => handleClick("2")}
                key="2"
                id="2"
                className="btn btn-outline-dark phonepad-btn"
              >
                2
              </button>
            </li>
            <li>
              <button
                name="dtmfKey"
                onClick={() => handleClick("3")}
                key="3"
                id="3"
                className="btn btn-outline-dark phonepad-btn"
              >
                3
              </button>
            </li>
            <li>
              <button
                name="dtmfKey"
                onClick={() => handleClick("4")}
                key="4"
                id="4"
                className="btn btn-outline-dark phonepad-btn"
              >
                4
              </button>
            </li>
            <li>
              <button
                name="dtmfKey"
                onClick={() => handleClick("5")}
                key="5"
                id="5"
                className="btn btn-outline-dark phonepad-btn"
              >
                5
              </button>
            </li>
            <li>
              <button
                name="dtmfKey"
                onClick={() => handleClick("6")}
                key="6"
                id="6"
                className="btn btn-outline-dark phonepad-btn"
              >
                6
              </button>
            </li>
            <li>
              <button
                name="dtmfKey"
                onClick={() => handleClick("7")}
                key="7"
                id="7"
                className="btn btn-outline-dark phonepad-btn"
              >
                7
              </button>
            </li>
            <li>
              <button
                name="dtmfKey"
                onClick={() => handleClick("8")}
                key="8"
                id="8"
                className="btn btn-outline-dark phonepad-btn"
              >
                8
              </button>
            </li>
            <li>
              <button
                name="dtmfKey"
                onClick={() => handleClick("9")}
                key="9"
                id="9"
                className="btn btn-outline-dark phonepad-btn"
              >
                9
              </button>
            </li>
            <li>
              <button
                name="dtmfKey"
                onClick={() => handleClick("10")}
                key="10"
                id="10"
                className="btn btn-outline-dark phonepad-btn"
              >
                *
              </button>
            </li>
            <li>
              <button
                name="dtmfKey"
                onClick={() => handleClick("0")}
                key="0"
                id="0"
                className="btn btn-outline-dark phonepad-btn"
              >
                0
              </button>
            </li>
            <li>
              <button
                name="dtmfKey"
                onClick={() => handleClick("11")}
                key="11"
                id="11"
                className="btn btn-outline-dark phonepad-btn"
              >
                #
              </button>
            </li>
          </ul>

          <button onClick={closeModal} className="btn mt-3 close-btn">
            Close
          </button>
        </Modal>
      </div>
      <div id="replaceAsVoiceWebRTCCaller"></div>
      <div id="modalStats" className="modal" style={{ display: "none" }}>
        <div className="modal-content">
          <div className="mdl-dialog__content custom-dialog-content">
            <div className="mdl-layout mdl-js-layout mdl-layout--fixed-header">
              <header className="stats-dialog-header">
                <span className="dialog-title" id="idCallStatistics"></span>
                <span id="close_button">
                  <i className="material-icons">close</i>
                </span>
              </header>
              <main className="mdl-layout__content">
                <div className="mdl-tabs mdl-js-tabs">
                  <div id="tabs" className="mdl-tabs__tab-bar">
                    <a
                      id="audioStatsTab"
                      href="#tab1-panel"
                      className="mdl-tabs__tab is-active"
                    ></a>
                    <a
                      id="videoStatsTab"
                      href="#tab2-panel"
                      className="mdl-tabs__tab"
                    ></a>
                  </div>

                  <div className="mdl-tabs__panel is-active" id="tab1-panel">
                    <div className="demo-list-action mdl-list">
                      <div className="mdl-list__item">
                        <span className="mdl-list__item-primary-content">
                          <span
                            id="idPacketsSentReceivedKey"
                            className="stat-key"
                          ></span>
                        </span>
                        <span className="mdl-list__item-secondary-content">
                          <span
                            id="idPacketsSentReceivedValue"
                            className="stat-values"
                          ></span>
                        </span>
                      </div>
                      <div className="mdl-list__item">
                        <span className="mdl-list__item-primary-content">
                          <span
                            id="idBytesSentReceivedKey"
                            className="stat-key"
                          ></span>
                        </span>
                        <span className="mdl-list__item-secondary-content">
                          <span
                            id="idBytesSentReceivedValue"
                            className="stat-values"
                          ></span>
                        </span>
                      </div>
                      <div className="mdl-list__item">
                        <span className="mdl-list__item-primary-content">
                          <span
                            id="idLossLocalRemoteKey"
                            className="stat-key"
                          ></span>
                        </span>
                        <span className="mdl-list__item-secondary-content">
                          <span
                            id="idLossLocalRemoteValue"
                            className="stat-values"
                          ></span>
                        </span>
                      </div>
                      <div className="mdl-list__item">
                        <span className="mdl-list__item-primary-content">
                          <span
                            id="idJitterLocalRemoteKey"
                            className="stat-key"
                          ></span>
                        </span>
                        <span className="mdl-list__item-secondary-content">
                          <span
                            id="idJitterLocalRemoteValue"
                            className="stat-values"
                          ></span>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="mdl-tabs__panel" id="tab2-panel">
                    <div className="demo-list-action mdl-list">
                      <div className="mdl-list__item">
                        <span className="mdl-list__item-primary-content">
                          <span
                            id="idHeaderReceive"
                            className="stat-header"
                          ></span>
                        </span>
                      </div>
                      <div className="mdl-list__item">
                        <span className="mdl-list__item-primary-content">
                          <span id="idBytesReceive" className="stat-key"></span>
                        </span>
                        <span className="mdl-list__item-secondary-content">
                          <span
                            id="idBytesReceiveValue"
                            className="stat-values"
                          ></span>
                        </span>
                      </div>
                      <div className="mdl-list__item">
                        <span className="mdl-list__item-primary-content">
                          <span
                            id="idPacketsReceive"
                            className="stat-key"
                          ></span>
                        </span>
                        <span className="mdl-list__item-secondary-content">
                          <span
                            id="idPacketsReceiveValue"
                            className="stat-values"
                          ></span>
                        </span>
                      </div>
                      <div className="mdl-list__item">
                        <span className="mdl-list__item-primary-content">
                          <span
                            id="idPacketsLostReceiveKey"
                            className="stat-key"
                          ></span>
                        </span>
                        <span className="mdl-list__item-secondary-content">
                          <span
                            id="idPacketsLostReceiveValue"
                            className="stat-values"
                          ></span>
                        </span>
                      </div>

                      <div className="mdl-list__item">
                        <span className="mdl-list__item-primary-content">
                          <span
                            id="idHeaderSend"
                            className="stat-header"
                          ></span>
                        </span>
                      </div>
                      <div className="mdl-list__item">
                        <span className="mdl-list__item-primary-content">
                          <span id="idBytesSend" className="stat-key"></span>
                        </span>
                        <span className="mdl-list__item-secondary-content">
                          <span
                            id="idBytesSendValue"
                            className="stat-values"
                          ></span>
                        </span>
                      </div>
                      <div className="mdl-list__item">
                        <span className="mdl-list__item-primary-content">
                          <span id="idPacketsSend" className="stat-key"></span>
                        </span>
                        <span className="mdl-list__item-secondary-content">
                          <span
                            id="idPacketsSendValue"
                            className="stat-values"
                          ></span>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </main>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AvayaComponent;
